import * as React from 'react'
import { Col, Row, Avatar } from 'antd-min';

export interface GroupCardProps {
    class?: string
    style?: React.CSSProperties
    onClick?: (e) => void
    index?: number
    isSelected?: boolean
    onClickGroup?: (index: number) => void
}
export interface HorizontalGroupCardProps extends GroupCardProps {
    contents: string[]
    src?: string
}
const GroupCard: React.StatelessComponent<HorizontalGroupCardProps> = (props) => {
    const contents = props.contents.filter(content => content);
    const contentClassName = `${!props.src && 'avatar-no-img'} ${contents.length > 1 ? 'group-card-contents' : 'group-card-content'}`
    return <div style={props.style} className={`${props.class} group-card ${props.isSelected && 'has-success has-feedback'} ${!props.src && 'gl-center-h'}`} onClick={(e) => { props.onClickGroup && props.onClickGroup(props.index); props.onClick && props.onClick(e) }}>
        <div style={{ display: props.src ? 'block' : 'none' }}><Avatar className='avatar-md avatar-img' src={props.src} /></div>
        <div className={contentClassName}>{contents.map((content, index) => <span title={content} key={index}>{content}</span>)}</div>
    </div>
}

export { GroupCard }

export interface RedeemCardProps {
    text?: string
    textElement?: JSX.Element
    contents?: string[]
    onClick?: () => void
}

const RedeemCard: React.StatelessComponent<RedeemCardProps> = (props) => {
    return <div className="redeem-code">
        {props.text && <div className="text" >{props.text}</div>}
        {!props.text && <div className="text">{props.textElement}</div>}
        <GroupCard contents={props.contents} onClick={props.onClick}></GroupCard>
    </div>
}

export { RedeemCard }

export interface GroupCardsStates {
    selected?: number
}
export class GroupCards extends React.Component<any, GroupCardsStates> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selected: 0
        }
    }
    renderChildCards() {
        const children = React.Children.toArray(this.props.children);
        let colspan = 0;
        switch (children.length) {
            case 1:
                colspan = 24;
                break;
            case 2:
                colspan = 12;
                break;
            default:
                colspan = 8;
                break;
        }
        return children.map((child, index) => {
            return <Col key={index}>
                {React.cloneElement(child as React.ReactElement<any>,
                    {
                        index: index,
                        onClickGroup: index => this.setState({ selected: index }),
                        // isSelected: this.state.selected === index
                    })}
            </Col>
        })
    }

    render() {
        return <Row type="flex" justify="center" gutter={32} className='group-cards'>
            {this.renderChildCards()}
        </Row>
    }
}