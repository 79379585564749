import * as React from 'react'
import { Component } from 'react';
import { Form, Row, Col, Button, Input, Radio } from "antd-min";
import { AdminPathConfig as PathConfig } from "../../../config/pathconfig";
import { lazyInject, TYPES } from '../../../util/index';
import { withRouter, GLForm, GLFormComponentProps, GLUtil, OidcProxy, GLGlobal, InvitationType, connect, StateType, MessageHelper, NotificationType } from "gl-commonui";
import { IInvitationService, InvitationRequest } from '../../../service/admin/acceptinvitation/index';
import { GSAdminLocale } from '../../../locales/localeid';
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from 'react-router';
import { GroupCard, GroupCards, RedeemCard } from '../../../components/group-card';
import { CONSTS, fmtMsg } from '../../../util/index';
import { IAccountService } from '../../../service/admin/accountservice/index';

const RadioGroup = Radio.Group;

export interface InvitationCodeProps extends GLFormComponentProps{
    isSignin?: boolean
}
interface InvitationCodeStates {
    headerContent?: string
    resource?: string
}

const mapStateToProps = (state: StateType) => {
    return {
        isSignin: state.oidc.loginInfo && state.oidc.loginInfo.loggedin,
    }
}

@withRouter
@GLForm.create()
@connect(({ oidc: { loginInfo } }: StateType) => ({
    isSignin: loginInfo && loginInfo.loggedin
}))
export class AcceptInvitationCodePage extends Component<InvitationCodeProps & RouteComponentProps<any>, InvitationCodeStates> {
    @lazyInject(TYPES.IInvitationService)
    service: IInvitationService
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    code = GLUtil.queryParse().code
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentDidMount() {
        this.GetInvitationInfo().then(data => {
            if (data.invitationType == InvitationType.globalHead 
                || data.invitationType == InvitationType.systemAdmin
                || data.invitationType == InvitationType.trainingAdmin) {
                this.setState({ 
                    headerContent: GSAdminLocale.InvitationGhOrTaTitle,
                    resource: CONSTS.InvitationType[data.invitationType]
                });
            } else if(data.invitationType == InvitationType.regionAdmin) {
                this.setState({ 
                    headerContent: GSAdminLocale.InvitationRegionAdminTitle,
                    resource: data.referenceResourceName
                });
            } else if(data.invitationType == InvitationType.trainer) {
                this.setState({ 
                    headerContent: GSAdminLocale.InvitationTrainerTitle,
                    resource: data.referenceResourceName
                });
            } else {

            }
        });
    }

    GetInvitationInfo() {
        const params: InvitationRequest = {
            invitationCode: this.code
        };
        return this.service.getInvitationInfoByCode(params);
    }

    bindingUser(data) {
        if (!this.props.isSignin) {
            MessageHelper.Message(NotificationType.Failed, GSAdminLocale.LoginFirstTitle);
            return;
        }
        const params = {
            id: GLGlobal.loginInfo().profile.sub,
            invitationCodeType: data.invitationType,
            invitationCodeId: data.id
        }
        this.accountservice.bindingUserById(params).then(() => {
                this.props.history.push({ pathname: PathConfig.AcceptInvitationSuccess, search: GLUtil.queryStringify({ email: GLGlobal.loginInfo().profile.email, code: this.code }) });
            }).catch(resp => {
                MessageHelper.Message(NotificationType.Failed, resp.body.error_description);
            });
    }

    useCurrentAccount() {
        this.GetInvitationInfo().then(data => {
            this.bindingUser(data);
        }).catch(resp => {
            MessageHelper.Message(NotificationType.Failed, resp.body.error_description);
        });
    }

    goToNext(text: string) {
        switch(text) {
            case "current":
                this.useCurrentAccount();
                break;
            case "existing":
                this.props.history.push({ pathname: PathConfig.RedeemExisting, search: GLUtil.queryStringify({ code: this.code })});
                break;
            case "register":
                this.props.history.push({ pathname: PathConfig.Register, search: GLUtil.queryStringify({ code: this.code })});
                break;
        }
    }

    render() {
        const form = this.props.form;
        const email = GLGlobal.loginInfo().profile && GLGlobal.loginInfo().profile.email;
        return <Row>
            <Row className='invitationsubject'>
                <FormattedMessage id={GSAdminLocale.InvitationSubject} ></FormattedMessage>
            </Row>
            <Row className='invitationtitle'>{this.state.headerContent && fmtMsg({ id: this.state.headerContent }, { resource: this.state.resource })}</Row>
            <Row className='selectuser'>
                {this.props.isSignin && <RedeemCard text={fmtMsg({ id: GSAdminLocale.InvitationCurrentText }, { email: email })} contents={[fmtMsg({ id: GSAdminLocale.InvitationCurrentAccount })]} onClick={() => this.goToNext("current")}></RedeemCard>}
                <RedeemCard text={fmtMsg({ id: GSAdminLocale.InvitationExistingText })} contents={[fmtMsg({ id: GSAdminLocale.InvitationExistingAccount })]} onClick={() => this.goToNext("existing")}></RedeemCard>
                <RedeemCard text={fmtMsg({ id: GSAdminLocale.InvitationRegisterText })} contents={[fmtMsg({ id: GSAdminLocale.RegisterAccount })]} onClick={() => this.goToNext("register")}></RedeemCard>
            </Row>
        </Row>;
    }
}